import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layouts'
import DayClasses from '../components/DayClasses'

// Query for the classes content in Prismic
export const query = graphql`
{
  allPrismicVenue{
    edges{
      node{
        id
        data {
          name {
            html
            text
            raw
          }
          address {
            html
            text
            raw
          }
        }
      }
    }
  }
  allPrismicClass(sort:{fields:data___order, order: ASC}){
    edges{
      node{
        id
        data {
          day
          discipline {
            document {
              ... on PrismicDiscipline {
                data {
                  name {
                    html
                    text
                    raw
                  }
                }
              }
            }
          }
          venue {
            document {
              ...on PrismicVenue {
                id
                data {
                  name {
                    html
                    text
                    raw
                  }
                  address {
                    html
                    text
                    raw
                  }
                }
              }
            }
          }
          name {
            html
            text
            raw
          }
          start_time {
            html
            text
            raw
          }
          end_time {
            html
            text
            raw
          }
        }
      }
    }
  }
}
`

export default ({ data }) => {
  // Define the Class content returned from Prismic
  const classes = data.allPrismicClass.edges.map(e => e.node);
  const venues = data.allPrismicVenue.edges.map(e => e.node);

  const days = classes.reduce((iter, c) => {
    if (!iter[c.data.day]) {
      iter[c.data.day] = [];
    }
    iter[c.data.day].push(c);
    return iter;
  }, {});

  return(
    <Layout title="Classes">
      <div className="pure-u-lg-3-5 pure-u-1-1 main">
        <h1>Classes</h1>
        <div class="pure-g">
          {Object.keys(days).map(day => {
            return <DayClasses day={day} classes={days[day]} />
          })}
        </div>
        <div className="pure-g">
          <div className="pure-u-lg-3-5 pure-u-1-1">
            <h2>Venues:</h2>
            {venues.map(venue => (
              <p>
                <a className="bookmark" name={`venue_${venue.id}`}>
                  <p>
                    <strong>{venue.data.name.text}</strong>
                    <br />
                    {venue.data.address.text}
                  </p>
                </a>
              </p>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}
