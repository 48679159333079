import React from 'react'

export default ({ day, classes }) => {
  if(!classes) return null;

  return(
    <div className="pure-u-lg-3-5 pure-u-1-1">
      <strong>{day}</strong>
      {classes.map((c) => {
        return (
          <>
            <p>
              {c.data.name.text} ({c.data.discipline.document.data.name.text})
              <br />
              {c.data.start_time.text}-{c.data.end_time.text}
              <br />
              <a href={`#venue_${c.data.venue.document.id}`}>
                {c.data.venue.document.data.name.text}
              </a>
            </p>
          </>
        )
      })}
    </div>
  )
}
